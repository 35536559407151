import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineCheck } from 'react-icons/ai';
import { trackPromise } from "react-promise-tracker";
import HeaderBottamSection from "../header/HeaderBottamSection";
import FooterSection from "../layout/FooterSection";
import { BsFillStarFill, BsStar } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import {  BsHeart, BsEye } from "react-icons/bs";
import {  toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router-dom";
function ProductDetails() {
  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const params = useParams();
  // console.log(params, "params----")
  const [counter, setCounter] = useState(1);
  const handleClick1 = () => {
    setCounter(counter - 1);
  };
  const handleClick2 = () => {
    setCounter(counter + 1);
  };
  const [ShopDetails, setShopDetails] = useState({});
  const [SizeDetails, setSize] = useState([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedSize, setSelectedSize] = useState('');
  const [ErrorSize, setErrorSize] = useState('');
  const [ErrorColor, setErrorColor] = useState('')

  const setColor = (item) => {
    setSelectedColor(item);
  }
  const setSizeItem = (item) => {
    setSelectedSize(item);
  }




  useEffect(() => {
    axios
      .post(`https://app.soundchatradio.com:3000/api/v1/auth/productdetails`, {
        id: params.prodoctId,
      })
      .then((response) => {
        setShopDetails(response.data.data);
        setSize(response.data.data.productavailabilities);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params]);
  const [Product, setProduct] = useState([]);
  useEffect(() => {
    trackPromise(
      axios
        .post(`https://app.soundchatradio.com:3000/api/v1/auth/listproduct`)
        .then((response) => {
          setProduct(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
    );
  }, []);
  
const jsonItems = JSON.parse(localStorage.getItem("card"));
function addToCart (){
  if((selectedSize === ""  && selectedColor === "") ||(selectedSize === ""  && selectedColor) || (selectedSize && selectedColor === "") ||(selectedColor === "") || (selectedSize === "") ){
    setErrorSize("plz selected size") 
    setErrorColor("plz selected color") 
  }else{
    showToastMessage("item added in card successfully")
  ShopDetails.sizeItem = selectedSize;
  ShopDetails.colorItem = selectedColor;
  ShopDetails.quantity =  counter;
   jsonItems.cart_items.push(ShopDetails)
   localStorage.removeItem('card')
   localStorage.setItem("card",JSON.stringify(jsonItems))
   setSelectedColor("");
   setSelectedSize("");
  }
   
   
}

useEffect(() => {
    if(jsonItems===null){
      localStorage.setItem("card",JSON.stringify({
        quantity: 0,
        total_amount: 0,
        cart_items: []
      }))
    }
}, [jsonItems]);
  
  return (
    <>
      <HeaderBottamSection />
      <div className="store-shoppiner">
        <div className="container">
          <Row>
            <div className="details-product">
              <Col sm={12} md={5} lg={5}>
                <div className="shop-i">
                  <img
                    src={
                      "https://app.soundchatradio.com/soundradiobackend/images/product/" +
                      ShopDetails.image
                    }
                    alt="img_err..."
                  />
                </div>
              </Col>
              <Col sm={12} md={7} lg={7}>
                <div className="product-dtl">
                  <div className="product-info">
                    <div className="product-name">{ShopDetails.title}</div>
                    <div className="reviews-counter">
                      <div className="rating">
                        <ul>
                          <li className="text-warning">
                            <BsFillStarFill />
                          </li>
                          <li className="text-warning">
                            <BsFillStarFill />
                          </li>
                          <li className="text-warning">
                            <BsFillStarFill />
                          </li>
                          <li>
                            <BsStar />
                          </li>
                          <li>
                            <BsStar />
                          </li>
                        </ul>
                        <span>3 Reviews</span>
                      </div>
                    </div>
                    <div className="product-price-discount">
                      <span>${ShopDetails.Price}</span>
                      <span className="line-through">${ShopDetails.Discount}</span>
                    </div>
                  </div>
                  <p>{ShopDetails.description}</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="product-variation product-size-variation">
                        <label className="product-label-text">Size:</label>
                         <div className="color-chooser">
                          {SizeDetails.map((item) => {
                            return (
                              <>
                                {" "}
                                <div
                                key={item.id}
                                  className={selectedSize === item.size ? 'color-item-1 color-item-selected-1' : 'color-item-1'}
                                  role="presentation"
                                  onClick={() => setSizeItem(item.size)}
                                >{item.size}</div>   
                              </>
                            );
                          })}
                        </div>
                        
                        <div>{(selectedSize==="") ? ErrorSize : <></>}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="product-variation product-size-variation">
                        <label className="product-label-text">Color:</label>
                        <div className="color-chooser">
                          {SizeDetails.map((item) => {
                            return (
                              <>
                                
                                <div
                                key={item.id}
                                  className={selectedColor === item.color ? 'color-item color-item-selected' : 'color-item'}
                                  style={{ backgroundColor: item.color }}
                                  role="presentation"
                                  onClick={() => setColor(item.color)}
                                >{selectedColor === item.color ? <AiOutlineCheck className="icon-color-size"/> :null}</div>
                               
                              </>
                            );
                          })}
                        </div>
                        <div>{(selectedColor==="") ? ErrorColor : <></>}</div>
                      </div>
                    </div>
                  </div>
                  <div className="product-count">
                    <label >Quantity:</label>
                    <form  className="display-flex mt-3">
                      <div className="qtyminus" onClick={handleClick1}>
                        -
                      </div>
                      <div className="qnt">{counter}</div>
                      <div className="qtyplus" onClick={handleClick2}>
                        +
                      </div>
                    </form>
                      <button className="round-black-btn" onClick={ () => addToCart()} >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </Col>
            </div>
            <Col sm={12} md={12} lg={12}>
              <div className="store-tabs">
                <Tabs
                  defaultActiveKey="Description"
                  transition={false}
                  id="noanim-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="Description" title="Description">
                    <div className="store-description">
                      <p>{ShopDetails.description}</p>
                    </div>
                  </Tab>
                  <Tab eventKey="Shipping" title="Additional information">
                    <div className="store-description">
                      <h4>Additional information</h4>
                      <p>
                        We deliver to over 100 countries around the world. For
                        full details of the delivery options we offer, please
                        view our Delivery information We hope you’ll love every
                        purchase, but if you ever need to return an item you can
                        do so within a month of receipt. For full details of how
                        to make a return, please view our Returns information
                      </p>
                    </div>
                  </Tab>
                  <Tab eventKey="Reviews" title="Reviews">
                    <div className="store-description">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 ">
                          <h4>Reviews</h4>
                          <p>There are no reviews yet.</p>
                          <div className="rating">
                            <ul>
                              <li className="text-warning">
                                <BsFillStarFill />
                              </li>
                              <li className="text-warning">
                                <BsFillStarFill />
                              </li>
                              <li className="text-warning">
                                <BsFillStarFill />
                              </li>
                              <li>
                                <BsStar />
                              </li>
                              <li>
                                <BsStar />
                              </li>
                            </ul>
                            <h5 className="mt-3">Your Rating</h5>
                          </div>

                          <form className="shop-form-reviews" >
                            <div className="row">
                              <div className="col-sm-12 col-md-6 ">
                                <label className="form-control-1-lable">
                                  Enter Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control-1"
                                  id="name"
                                  name="name"
                                  value=""
                                  required
                                />
                              </div>

                              <div className="col-sm-12 col-md-6 ">
                                <label className="form-control-1-lable">
                                  Enter Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control-1"
                                  id="email"
                                  name="email"
                                  value=""
                                  required
                                />
                              </div>
                              <div className="col-sm-12 col-md-12 ">
                                <label className="form-control-1-lable">
                                  Enter Message
                                </label>
                                <textarea
                                  className="form-control-1"
                                  rows="4"
                                  name="message"
                                  required
                                ></textarea>
                              </div>
                              <div className="col-sm-12 col-md-6 ">
                                <button id="submit" type="submit" value="SEND">
                                  submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </Col>
            <section className="section-products">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="header">
                    <h4> Related Products</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                {Product.map((element) => {
                  return (
                    <>
                      <div className="col-sm-6 col-6 col-md-3 col-lg-4 col-xl-3" key={element.id}>
                        <NavLink
                          key={"/ProductDetails"}
                          to={`/ProductDetails/${element.id}`}
                          state={{
                            currentvideo: element,
                            related: Product,
                          }}
                        >
                          <div id="product-2" className="single-product">
                            <div className="part-1">
                              <img
                                src={
                                  "https://app.soundchatradio.com/soundradiobackend/images/product/" +
                                  element.image
                                }
                                alt="img-error"
                              />{" "}
                              <span className="discount">
                                {element.Discount} off
                              </span>
                              <ul>
                                {/* <li>
                                  <NavLink to="/Cart">
                                    <i>
                                      <BsCart3 />
                                    </i>
                                  </NavLink>
                                </li> */}
                                <li>
                                  <NavLink to="/Login">
                                    <i>
                                      <BsHeart />
                                    </i>
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/ProductDetails">
                                    <i>
                                      <BsEye />
                                    </i>
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                            <div className="part-2">
                              <h3 className="product-title">{element.title}</h3>
                              <h4 className="product-price">${element.Price}</h4>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </>
                  );
                })}
              </div>
            </section>
          </Row>
        </div>
      </div>

      <FooterSection />
    </>
  );
}
export default ProductDetails;
