import React,{useEffect} from 'react'
import FooterSection from '../compontes/layout/FooterSection'
// import InterviewSection from '../compontes/layout/InterviewSection'
import NewsSection from '../compontes/layout/NewsSection'
// import PodcastSection from '../compontes/layout/PodcastSection'
import SoundchatVideoSection from '../compontes/layout/SoundchatVideoSection'
import HeaderTopSection from '../compontes/header/HeaderTopSection';
import HeaderBottamSection from '../compontes/header/HeaderBottamSection'
import { useState,Suspense  } from 'react';
const InterviewSection = React.lazy(() => import("../compontes/layout/InterviewSection"));

export default function Home() {
  const [audioplayerstatus,setaudioplayerstatus]=useState("pause")


  function callbackheader(status){
    setaudioplayerstatus(status)
    console.log(status,'home')
  }
  return (
    <>
    <HeaderTopSection callback={callbackheader}/>
    <HeaderBottamSection />
    <SoundchatVideoSection props={audioplayerstatus}/>
    {/* <PodcastSection/> */}
    <NewsSection/>
    <Suspense fallback={<div>Loading</div>}>
    <InterviewSection/>
			</Suspense>
    <FooterSection/>
    </>
  )
}




