import React,{useEffect,useState} from "react";
// import reactCSS from 'reactcss'
import "./App.css";
import { ToastContainer } from "react-toastify";
import Router from "./routes";
import AudioPlayers from "./pages/AudioPlayers";
import { BsFillChatDotsFill } from 'react-icons/bs';
import {  useLocation } from "react-router-dom";

export default function MainApp() {
  const pathlocation = useLocation()
  // console.log(pathlocation,pathlocation)
  const token  = JSON.parse(localStorage.getItem("accessToken"))
  const guestlogin  = JSON.parse(localStorage.getItem("guestlogin"))
 
  const Location = localStorage.getItem("locationpath")
  const [oldlocation,setoldlocation]=useState("/")
  const [locationstatus,setlocationstatus]=useState("/")
  const [ChatIconDel, setChatIconDel] = useState(false)
  const [LoginGuest, setLoginGuest] = useState(false)

  //  window.location.reload(true)
  const data= (location)=>{
    localStorage.setItem("locationpath",location)
    setoldlocation(location)

    // window.location.reload(false)
  }
  useEffect(()=>{
    setlocationstatus(oldlocation)
  },[oldlocation])

  useEffect(()=>{
    if(token===null || token===undefined){
      setChatIconDel(true)
    }else{
      setChatIconDel(false)
    }
  },[token])

  useEffect(()=>{
    if(guestlogin===null || guestlogin===undefined || guestlogin===""){
      setLoginGuest(true)
    }else{
      setLoginGuest(false)
    }
  },[guestlogin])
  return (
    <>
     <a href="/Chatting" target="_blank">
   {ChatIconDel  ? <div className="icon-hide-chat" style={{display:"none", visibility:"hidden"}}></div> : <><BsFillChatDotsFill className="chaticon-mobile"/></> }
    </a> 
    <a href="/Chatting" target="_blank">
   {LoginGuest  ? <div className="icon-hide-chat" style={{display:"none", visibility:"hidden"}}></div> : <><BsFillChatDotsFill className="chaticon-mobile"/></> }
    </a>
        {(locationstatus==='/')?<>
     <AudioPlayers props={data}/>
      <Router />
       </>:<>
       <Router />
      <AudioPlayers props={data}/></>}
      <ToastContainer/>
    </>
  );
}

