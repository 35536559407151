import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import axios from "axios";
import ColorPicker from "../compontes/ColorPicker";
import TextColorChat from "../compontes/TextColorChat";
import socketIO from "socket.io-client";
import { BsFillCursorFill, BsEmojiSmileFill } from "react-icons/bs";
import { ImBold, ImItalic, ImUnderline } from "react-icons/im";
export default function ChatComponents() {
  const el = useRef(null);
  const socket = socketIO.connect(`https://chat.soundchatradio.com:8000/`);
  const color = JSON.parse(localStorage.getItem("color"));
  const textColorData = JSON.parse(localStorage.getItem("textcolor"));
  const userId = localStorage.getItem("id");
  const username = JSON.parse(localStorage.getItem("userName"));
  const time = moment().tz("America/Detroit");
  const [ChatMessage, setChatMessage] = useState([]);
  const [value, setValue] = useState("");
  const [messageDataObj, setmessageDataObj] = useState({});
  const [underline, setUnderline] = useState(false);
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [TextColor, setTextColor] = useState("");
  const [BackgroundColor, setBackgroundColor] = useState("");
  const [RightData, setRightData] = useState([]);
  ///chat///
  useEffect(() => {
    setBackgroundColor(color.hex);
    setTextColor(textColorData.hex);
    // setBackgroundColor(color);
    // setTextColor(textColorData);
  }, [localStorage.getItem("color"), localStorage.getItem("textcolor")]);

  useEffect(() => {
    el.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'nearest' });
});

  useEffect(() => {
    setmessageDataObj({
      bold: bold ? 1 : 0,
      italic: italic ? 1 : 0,
      underline: underline ? 1 : 0,
      messageBubbleColor: BackgroundColor,
      textColor: TextColor,
      message: value,
    });
  }, [
    value,
    underline,
    bold,
    italic,
    TextColor,
    BackgroundColor,
    localStorage.getItem("color"),
    localStorage.getItem("textcolor"),
  ]);

  useEffect(() => {
    // trackPromise(
    axios
      .get(`https://chat.soundchatradio.com:8000/group_chat_history`)
      .then((response) => {
        setChatMessage(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // );
  }, []);

  function boldFunc() {
    if (bold === false) {
      setBold(true);
    } else {
      setBold(false);
    }
  }
  function italicFunc() {
    if (italic === false) {
      setItalic(true);
    } else {
      setItalic(false);
    }
  }
  function underlineFunc() {
    if (underline === false) {
      setUnderline(true);
    } else {
      setUnderline(false);
    }
  }

  function handleSendMessage(e) {
    e.preventDefault();
    if (value.trim() && username) {
      socket.emit("send_message", {
        id: 0,
        status: 0,
        name: username,
        roomId: 123,
        senderId: userId,
        time: time,
        bold: bold ? 1 : 0,
        italic: italic ? 1 : 0,
        underline: underline ? 1 : 0,
        messageBubbleColor: BackgroundColor,
        textColor: TextColor,
        message: value,
        messageType: "text",
      });

      setValue("");
    }
  }

  let temp = [];
  useEffect(() => {
    socket.on("receive_message", (data) => {
      temp.push(data);
      setRightData(temp);
    });
  }, [socket, temp]);

  return (
    <div>
      <div className="chat-section ">
        <section className="msger">
          <header className="msger-header">
            <div className="msger-header-title">
              <i className="fas fa-comment-alt"></i> Chatroom
            </div>
            <div className="msger-header-options">
              <span>
                <i className="fas fa-cog"></i>
              </span>
            </div>
          </header>
          <main className="msger-chat">
            {ChatMessage.map((chat) => {
              return (
                <>
                  {/* {chat.name === "fyapp1965@gmail.com." ? 
                         (
                          <> 
                            <div className="msg right-msg">
                              <div
                                className="msg-bubble"
                                style={{
                                  backgroundColor:
                                    "#" + chat.messageBubbleColor.slice(2),
                                }}
                              >
                                <div className="msg-info">
                                  <div
                                    className="msg-info-name"
                                    style={{ color: "yellow" }}
                                  >
                                    {chat.name}
                                  </div>
                                </div>
                                <div
                                  className="msg-text"
                                  style={{
                                    color: "#" + chat.textColor.slice(2),
                                  }}
                                >
                                  {chat.messageType === "text" ? (
                                    <> {chat.message}</>
                                  ) : (
                                    <>
                                      <img src={chat.message} alt="my-gif" className="gif-text"  />
                                    </>
                                  )}
                                  <div className="msg-info-time">12:46</div>
                                </div>
                              </div>
                            </div>
                          </>
                        )  */}

                  {/* // : ( */}
                  {/* <> */}
                  <div className="msg left-msg">
                    <div
                      style={{
                        backgroundColor: "#" + chat.messageBubbleColor.slice(2),
                      }}
                      className="msg-bubble"
                    >
                      <div className="msg-info">
                        <div
                          className="msg-info-name"
                          style={{ color: "yellow" }}
                        >
                          {chat.name}
                        </div>
                      </div>
                      <div
                        className="msg-text"
                        style={{
                          color: "#" + chat.textColor.slice(2),
                          fontWeight: chat.bold ? "bold" : "",
                          fontStyle: chat.italic ? "italic" : "",
                          textDecorationLine: chat.underline ? "underline" : "",
                        }}
                      >
                        {chat.messageType === "text" ? (
                          <> {chat.message}</>
                        ) : (
                          <>
                            <img
                              src={chat.message}
                              alt="my-gif"
                              className="gif-text"
                            />
                          </>
                        )}
                      </div>
                      <div className="msg-info-time">
                        {moment(chat.createdAt)
                          .tz("America/Detroit")
                          .format("MMM D h:mm")}
                      </div>
                    </div>
                  </div>
                  {/* </> */}
                  {/* // )} */}
                </>
              );
            })}

            {RightData.map((element) => {
              return (
                <>
                  <div className="msg left-msg">
                    <div
                      className="msg-bubble"
                      style={{
                        backgroundColor:
                          "#" + element.messageBubbleColor.slice(2),
                      }}
                    >
                      <div className="msg-info">
                        <div
                          className="msg-info-name"
                          style={{ color: "yellow" }}
                        >
                          {element.name}
                        </div>
                      </div>
                      <div
                        className="msg-text"
                        style={{
                          color: "#" + element.textColor.slice(2),
                          fontWeight: element.bold ? "bold" : "",
                          fontStyle: element.italic ? "italic" : "",
                          textDecorationLine: element.underline
                            ? "underline"
                            : "",
                        }}
                      >
                        {element.messageType === "text" ? (
                          <> {element.message}</>
                        ) : (
                          <>
                            <img
                              src={element.message}
                              alt="my-gif"
                              className="gif-text"
                            />
                          </>
                        )}
                      </div>
                      <div className="msg-info-time">
                        {moment(element.createdAt)
                          .tz("America/Detroit")
                          .format("MMM D h:mm")}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <>
              <div className="msg right-msg" ref={el}>
                <div
                  className="msg-bubble"
                  style={{
                    backgroundColor: messageDataObj.messageBubbleColor,
                  }}
                >
                  <div className="msg-info">
                    <div className="msg-info-name" style={{ color: "yellow" }}>
                      {username}
                    </div>
                  </div>
                  <div
                    className="msg-text"
                    style={{
                      color: messageDataObj.textColor,
                      fontWeight: messageDataObj.bold ? "bold" : "",
                      fontStyle: messageDataObj.italic ? "italic" : "",
                      textDecorationLine: messageDataObj.underline
                        ? "underline"
                        : "",
                    }}
                  >
                    {messageDataObj.message}
                    {/* {chat.messageType === "text" ? (
                                    <> {chat.message}</>
                                  ) : (
                                    <>
                                      <img src={chat.message} alt="my-gif"   className="gif-text" />
                                    </>
                                  )} */}
                  </div>
                  <div className="msg-info-time">12:46</div>
                </div>
              </div>
            </>
          </main>
          <div className="chat-fun">
            <ul>
              <li className="chat-input-icon">
                <ColorPicker />
              </li>
              <li className="chat-input-icon">
                <TextColorChat />
              </li>
              <li onClick={() => boldFunc()} className="chat-input-icon">
                <ImBold />
              </li>
              <li onClick={() => italicFunc()} className="chat-input-icon">
                <ImItalic />
              </li>
              <li onClick={() => underlineFunc()} className="chat-input-icon">
                <ImUnderline />
              </li>
            </ul>
          </div>
          <form className="msg-chatt-footer" onSubmit={handleSendMessage}>
            {/* <h6 className="send-plus">
                    <BsPlusCircle />
                  </h6>
                  <h6 className="send-plus">
                    <BsImages />
                  </h6> */}
            <input
              value={value}
              type="text"
              className="msg-chatt-text"
              placeholder="Write a message..."
              autoComplete="off"
              style={{ color: "#000 !importants" }}
              onChange={(e) => setValue(e.target.value)}
            />
            <button
              className="btn btn--send"
              title="Send message"
              // tabindex="0"
            >
              {/* <span className="emoji">
                <BsEmojiSmileFill />
              </span> */}

              <h5 className="send-bn">
                <BsFillCursorFill />
              </h5>
            </button>
          </form>
        </section>
      </div>
    </div>
  );
}
