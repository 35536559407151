import React, { useState, useCallback, useEffect } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
// import { BsFillPlayFill } from "react-icons/bs";
import HeaderBottamSection from "../compontes/header/HeaderBottamSection";
import FooterSection from "../compontes/layout/FooterSection";
import OwlCarousel from "react-owl-carousel";
import { NavLink } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import axios from "axios";
import ReactPaginate from "react-paginate";
// import { SettingsOutlined } from "@mui/icons-material";
export default function MediyGallery() {
  
  const [RandomImgGally, setRandomImgGally] = useState([]);
  // pagination 
  const [offset, setOffset] = useState(0);
  const [perPage, setperPage] = useState(12);
  const [selectedPage, setselectedPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setselectedPage(selectedPage+1);
    setOffset(selectedPage+1);
  };
  // console.log("offset---",offset)
  // console.log("selectedPage---", selectedPage)
  // console.log(selectedPage);
  // useEffect(() => {
  //   getData();
  // }, [offset]);

  //

  const { promiseInProgress } = usePromiseTracker();
  // console.log(promiseInProgress);
  const state = {
    margin:10,
    nav:true,
    responsive: {
      
      0: {
        items: 1,
      },
      320: {
        items: 2,
      },
      450: {
        items: 3,
      },
      767: {
        items: 4,
      },
      768: {
        items: 4,
      },
      991: {
        items: 5,
      },
      1200: {
        items: 8,
      },
    },
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const photos = [];

  const [GalleryScroll, setGalleryScroll] = useState([]);
  const [GalleryImg, setGalleryImg] = useState([]);
  const [Title, setTitle] = useState('');
  useEffect(() => {
    trackPromise(
      axios
        .post(`https://app.soundchatradio.com:3000/api/v1/auth/listgallery`)
        .then((response) => {
          setGalleryScroll(response.data.data);
          setGalleryImg(response.data.data[0].galleryimages);
          setTitle(response.data.data[0].post_title)
        })
        .catch((error) => {
          console.log(error);
        })
    );
  }, []);
  

  // console.log("GalleryScroll---", GalleryScroll)
  useEffect( () =>{
   const RandamData = [...GalleryScroll]
   var shuffled = RandamData.sort(function () {
    return 0.5 - Math.random();
  });
  var selectedImg = shuffled.slice(0, 5);
  setRandomImgGally(selectedImg)
  },[GalleryScroll])
  // console.log("RandomImgGally---", RandomImgGally)

  useEffect(() => {
    setPageCount(Math.ceil(GalleryImg.length / perPage));
  },[GalleryImg, GalleryImg.length, perPage] );
  const indexOfLastRecord = selectedPage * perPage;
  const indexOfFirstRecord = indexOfLastRecord - perPage;
  //  console.log(
  //   indexOfFirstRecord, "indexOfFirstRecord"
  //   perPage,
  //   selectedPage,
  //   pageCount,
  //   GalleryImg.length
  //  );
  // console.log(indexOfLastRecord);
  // Records to be displayed on the current page
  const currentRecords = GalleryImg.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  for (let i = 0; i < currentRecords.length; i++) {
    // if(currentRecords.length===1){

    // }
    const rndInt = randomIntFromInterval(2,2)
    const rndInt2 = randomIntFromInterval(1,2)
    photos.push({
      src:
        "https://app.soundchatradio.com/soundradiobackend/images/gallery/" +
        currentRecords[i].image_name,
      width: rndInt,
      height: rndInt2,
    });
  }



  // useEffect(() => {
  //   trackPromise(
  //     axios
  //       .post(`https://app.soundchatradio.com:3000/api/v1/auth/interviews`)
  //       .then((response) => {
  //         setEventOneApiCall(response.data.data.EVENTS);
  //         setYelloOneApiCall(response.data.data["YELLOW-TENT"]);
  //         setInStOneApiCall(response.data.data["IN-STUDIO"]);
  //         setSoundSystemOneApiCall(response.data.data["SOUND SYSTEM CULTURE"]);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //   );
  // }, []);

  // useEffect(() => {
  //   trackPromise(
  //     axios
  //       .post(
  //         `https://app.soundchatradio.com:3000/api/v1/auth/latestcontentlist`
  //       )
  //       .then((response) => {
  //         setLatestInterviewApiCall(response.data.data.interview);
  //         setInterviewVideo(response.data.data.interview);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //   );
  // }, []);

  // useEffect(() => {
  //   trackPromise(
  //     axios
  //       .post(
  //         `https://app.soundchatradio.com:3000/api/v1/auth/listphoneinterviews`
  //       )
  //       .then((response) => {
  //         setPhoneInterviewOneApiCall(response.data.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       })
  //   );
  // }, []);

 
// console.log(selectedPage,"selectedpage")

  return (
    <>
      <HeaderBottamSection />
      {RandomImgGally.length>0? (<>
      <OwlCarousel
          items={1}
          className="owl-carousel owl-theme"
          loop
          autoplay={true}
          dots={false}
          touchDrag={true}
          lazyLoad={true}
          margin={25}
        >
        {(RandomImgGally.length>0) ? <>  {RandomImgGally.length &&RandomImgGally.map((element, index) => {
            return (
              
                <div key={index} className="slider-new">
                  <div className="video-slider">
                    <img
                      src={
                        "https://app.soundchatradio.com/soundradiobackend/images/gallery/" +
                        element.feature_img
                      }
                      alt=""
                    />
                    <div className="recap-video-heading">
                      {/* <div className="video-recap-btn-div">
                        <span className="recap-icon-w-div">
                          <BsFillPlayFill />
                        </span>
                        <NavLink
                          key={"/SoundSystemViewVideo"}
                          to="/SoundSystemViewVideo"
                          state={{
                            currentvideo: element,
                            related: InterviewVideo,
                          }}
                        >
                          <button> &nbsp; &nbsp;WATCH NOW</button>
                        </NavLink>
                      </div> */}
                      <h1 className="recap-video-h1">{element.post_title}</h1>
                      <p className="recap-video-pra">{element.post_content}</p>
                    </div>
                  </div>
                </div>
              
            );
          })}
</>:<></>}
        </OwlCarousel>
        </>) :(<></>)}
      <section className="">
      
        {promiseInProgress === true ? (
          <div
            className="spinner"
            style={{
              display: "flex",
              justifyContent: "center",
              alignIitems: "center",
              height:"300px",
            }}
          >
            <ThreeDots
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="mediy-gallery-div">
              <div className="heading-h4-div">
                <h4>Media Album</h4>
              </div>
              <OwlCarousel
                items={3}
                className="owl-carousel owl-theme tab-click"
                // loop
                nav={true}
                autoplay={false}
                dots={false}
                touchDrag={true}
                lazyLoad={true}
                responsive={state.responsive} // add this line
                margin={25}
                
              >
                {GalleryScroll.map((gallerydata, index) => {
                  return (
                    <div key={index}>
                      <NavLink
                      className=""
                        // key={"/GalleryPage"}
                        // to="/GalleryPage"
                        // state={gallerydata.galleryimages}
                        onClick={() => {
                          setGalleryImg(gallerydata.galleryimages);
                          setPageCount(gallerydata.galleryimages.length);
                          setTitle(gallerydata.post_title)
                          setselectedPage(1)
                          setOffset(0)
                          
                          // if(!selectedPage === ResetValue){
                          //   setselectedPage(ResetValue)
                          // }
                          // selectedPage(ResetValue)
                          // setselectedPage(selectedPage + 1);
                          //  setPageCount(0)
                        }}
                      >
                        <div key={gallerydata.id} className="podcast-div" >
                          <div className="podcast-img  effect-image-1 radious-effect radius-effct-next">
                            <img
                              src={
                                "https://app.soundchatradio.com/soundradiobackend/images/gallery/" +
                                gallerydata.feature_img
                              }
                              alt=""
                            />
                          </div>
                          <div className="podcast-box">
                            
                            {(Title===gallerydata.post_title)?<><h6 className="text-success">{gallerydata.post_title}</h6></>:<><h6>{gallerydata.post_title}</h6><p className="div-podcast">VIEW ALBUM</p></>}
                            
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>

            <div className="media-gallary-heading">
              <h3>{Title}</h3>
            </div>
            <div className="gallery">
              <div className="gallery-div">
                <Gallery photos={photos} onClick={openLightbox} />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={photos.map((x) => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title,
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
              {/* <div className="media-pagination">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={(e)=>{handlePageClick(e)}}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  //  initialPage={selectedPage}
                  forcePage={selectedPage-1}
                />
              </div> */}
            </div>
          </>
        )}
         <div className="media-pagination">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={(e)=>{handlePageClick(e)}}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  //  initialPage={selectedPage}
                  forcePage={selectedPage-1}
                />
              </div>
      </section>
      <FooterSection />
    </>
  );
}
